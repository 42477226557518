import {Box, IconButton, Stack} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import {LoadableImagePreview} from "./LoadableImagePreview";
import {UploadedFile} from "../utils/files";
import {LoadableFile} from "./LoadableFile";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
    files: UploadedFile[],
    size?: number | string,
    onClick?: () => void
}

export const LoadableImagePreviewExpandable = ({files, size} : Props) => {
    const [activePhoto, setActivePhoto] = React.useState<string | undefined>();
    const handleClose = (value: string) => {
        setActivePhoto(undefined);
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        margin: '10px',
                        position: 'relative'
                    }
                }}
                open={!!activePhoto}
            >
                <IconButton


                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        zIndex:1,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background for visibility
                        color: 'white', // Ensures visibility on all images
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        }
                    }}
                    onClick={() => handleClose('')}


                >


                    <CloseIcon />
                </IconButton>
                <Stack sx={{minWidth:{xs: '300px'}}} alignItems={'center'} >

                    <LoadableImagePreview src={activePhoto} />
                </Stack>
            </Dialog>
            <Box>
                {files.map((file, index) =>
                    (
                        <Box key={index}>
                            <LoadableImagePreview onClick={() => setActivePhoto(file.path)} src={file.path} />
                            <LoadableFile files={[file]}/>
                        </Box>
                    )
                )}
            </Box>

        </>
    )
}
