import {buildCustomSlice, buildSlice, createGenericAsyncThunk} from "../../../generic";
import {Chat, ChatItem, ChatItemDate, ChatItemMessage, PageableList} from "../../../types";
import dayjs from "dayjs";
import {createSelector} from "@reduxjs/toolkit";
import {AppState} from "../../index";
import {API_URL} from "../../../config";
import {UploadedFile} from "../../../utils/files";

export type ChatMessage = {
    message: string;
    createdOn: string;
    files: UploadedFile[];
    chatMessageType: 'TEXT' | 'IMAGE' | 'FILE'
    userFullName: string;
    userId: string;
}

const convertToChatItem = (data: PageableList<ChatMessage>, userId: string): PageableList<ChatItem> => {
    const result: PageableList<ChatItem> = {...data, content: [] as ChatItem[]}

    let startDay = '';
    const resultContent: ChatItem[] = [];
    for(let i = data.content.length - 1; i >= 0; i--) {
        let message = data.content[i];
        const messageDate = dayjs(message.createdOn);
        const messageDay = messageDate.format('MMM D');
        const [month, day] = messageDay.split(' ');
        if (messageDay !== startDay) {
            resultContent.push({type: 'date', day, month } as ChatItemDate);
            startDay = messageDay;
        }
        resultContent.push({
            type: message.chatMessageType === 'TEXT' ? 'message' : message.chatMessageType.toLowerCase(),
            files: message.files,
            message: message.message,
            time: messageDate.format('HH:mm'),
            author: message.userFullName,
            userMessage: message.userId === userId} as ChatItemMessage
        );
    }
    result.content = resultContent;

    return result;
};

export const sendChatMessage = createGenericAsyncThunk<void, { chatId: number, message: string, studentId  : number}>(
    'chats/sendMessage',
    async ({ studentId, message, chatId }, { extra, rejectWithValue }) => {
        const response = await extra.ajax['post'](`${API_URL}/api/public/v1/students/${studentId}/chats/${chatId}/messages`, { message });
        const data = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
    }
);

export const fetchUserChats = createGenericAsyncThunk<Chat[], { studentId: number, language: string }>(
    'chats/fetchUseChats',
    async ({ studentId, language }, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(
            `${API_URL}/api/public/v1/students/${studentId}/chats`
        );
        const data: Chat[] = await response.json();
        data.forEach(c => c.chatName = c.type === 'TEACHER_CHAT' ? c.teacherFullName : language === 'PL' ? 'Administracja Szkoły' : 'School Administration')
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
);

export const fetchLatestUserFacilityChatMessages = createGenericAsyncThunk<PageableList<ChatItem>, { studentId: number, userId: string, chatId: number}>(
    'chats/fetchLatestUserFacilityChatMessages',
    async ({ studentId, userId, chatId }, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(`${API_URL}/api/public/v1/students/${studentId}/chats/${chatId}/messages?page=0&size=15`);
        const data: PageableList<ChatMessage> = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return convertToChatItem(data, userId);
    }
);

export const fetchMoreUserFacilityChatMessages = createGenericAsyncThunk<PageableList<ChatItem>, { chatId: number, studentId: number, userId: string, page: number, size: number}>(
    'chats/fetchMoreUserFacilityChatMessages',
    async ({ chatId, studentId, userId, page, size }, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(`${API_URL}/api/public/v1/students/${studentId}/chats/${chatId}/messages?page=${page}&size=${size}`);
        const data: PageableList<ChatMessage> = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return convertToChatItem(data, userId);
    }
);

export const userChatMessagesSliceNew = buildCustomSlice<PageableList<ChatItem>>(
    'user/ChatFetchSliceNew',
    (builder) => {

        builder.addCase(fetchLatestUserFacilityChatMessages.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = action.payload;
            delete state.error;
        });

        builder.addCase(fetchMoreUserFacilityChatMessages.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = state.data ? {...action.payload, content: [...action.payload.content, ...state.data.content]} : action.payload;
            delete state.error;
        });
    }
);

export const userChatsSlice =  buildSlice<Chat[], { studentId: number, language: string }>(
    'chatFetchSlice', fetchUserChats.fulfilled
);
export const userChatsReducer = userChatsSlice.reducer;
export const selectUserChats = createSelector([(state: AppState) => state.chats], (messages) => messages.data);

export const userChatMessagesReducerNew = userChatMessagesSliceNew.reducer;
export const selectUserChatMessagesPerChat = createSelector([(state: AppState) => state.chatMessagesNew], (messages) => messages.data);
