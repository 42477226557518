import React from 'react';
import { AttachFile, Description, Image, PictureAsPdf, VideoFile } from '@mui/icons-material';
import { List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { fetchFileFull, UploadedFile} from "../utils/files";
import {isStandaloneMobileApp} from "../utils/misc";

interface AttachedFilesProps {
    files: UploadedFile[];
}

const getFileIcon = (contentType: string) => {
    if (contentType.startsWith('image/')) {
        return <Image />;
    } else if (contentType === 'application/pdf') {
        return <PictureAsPdf />;
    } else if (contentType.startsWith('video/')) {
        return <VideoFile />;
    } else {
        return <Description />;
    }
};

export const LoadableFile: React.FC<AttachedFilesProps> = ({ files }) => {

    const handleDownload = async (file: UploadedFile) => {
        const fetchedFile = await fetchFileFull(file.path);
        const files = [new File([fetchedFile.blob], file.originalName, { type: file.contentType })];
        if (isStandaloneMobileApp() && navigator.canShare && navigator.canShare({files})) {
            await navigator.share({
                files: files as File[]
            });
            URL.revokeObjectURL(fetchedFile.url as string);
            return;
        }
        try {

            const link = document.createElement('a');
            link.href = fetchedFile.url as string;
            link.download = file.originalName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // Clean up the blob URL
            URL.revokeObjectURL(fetchedFile.url as string);
        } catch (error) {
            console.error('Error downloading file:', error);
            // You might want to show an error message to the user here
        }
    };

    if (!files || files.length === 0) return null;

    return (
        <List dense>
            {files.map((file, index) => (
                <ListItem
                    key={index}
                    disableGutters
                    secondaryAction={
                        <IconButton edge="end" onClick={() => handleDownload(file)}>
                            <AttachFile />
                        </IconButton>
                    }
                >
                    <ListItemIcon>
                        {getFileIcon(file.contentType)}
                    </ListItemIcon>
                    <ListItemText
                        primary={file.originalName}
                        sx={{
                            '.MuiListItemText-primary': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }
                        }}
                    />
                </ListItem>
            ))}
        </List>
    );
};


