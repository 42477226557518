import {Box} from '@mui/material';
import * as React from 'react';
import {CSSProperties, useEffect, useState} from 'react';
import {fetchImage} from "../utils/files";
import {SxProps} from "@mui/system";


type Props = {
  src?: string,
  height?: number | string,
  width?: number | string,
  imgStyle?: CSSProperties,
  onClick?: () => void,
  sx?: SxProps
  wrapper?: boolean
}

type PropsImg =  {
  loading: boolean,
  imageUrl?: string,
  onClick?: () => void,
  imgStyle?: CSSProperties,
}


const Image = ({loading, imageUrl, onClick, imgStyle}: PropsImg) => {
  return <>
    {loading && <img alt={'Loading...'} src={'/loader.gif'} style={{ width: '25%', opacity: 0.3}} />}

    {imageUrl  && (
          <img src={imageUrl} loading="lazy" onClick={onClick} onLoad={() => URL.revokeObjectURL(imageUrl)}
               style={{width: '100%', display:'block', height: '100%', objectFit: 'cover', ...imgStyle}}/>
    )}
  </>
}

export const LoadableImagePreview = ({src, height, width, imgStyle, sx, onClick, wrapper = true}: Props) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(src) {
      setLoading(true);
      fetchImage(src).then((r) => {
        setImageUrl(r);
        setLoading(false);
      });
    }

  }, [src]);

  return (
      <>
      { wrapper && (<Box sx={{
        position: 'relative',
        display: 'inline-block',
        textAlign: 'center',
        alignContent: 'center',
        width: width,
        height: height,
        ...sx
      }}>

        <Image imageUrl={imageUrl} onClick={onClick} loading={loading} imgStyle={imgStyle}/>
      </Box>)
      }
        { !wrapper  && <Image imageUrl={imageUrl} onClick={onClick} loading={loading} imgStyle={imgStyle}/> }
      </>
  )
}
